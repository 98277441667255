import axios from 'axios';
import keys from 'src/utils/keys';
import { getDeviceId } from 'src/utils/main';

const AuthAPI = axios.create({
  baseURL: keys.SERVER_URL,
  responseType: 'json',
  timeout: 10000,
});

AuthAPI.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem('admin_access_token')}`;
    req.headers.device = getDeviceId();

    return req;
  },
);

export default AuthAPI;
