import axios from 'axios';
import keys from 'src/utils/keys';
import { getDeviceId } from 'src/utils/main';

const API = axios.create({
  baseURL: keys.SERVER_URL,
  responseType: 'json',
  timeout: 10000,
});

API.interceptors.request.use(
  (req) => {
    req.headers.device = getDeviceId();

    return req;
  },
)

export default API;
