import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './scss/style.scss';

import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getBunnedUsers, getMe } from './store/Slices/users';

dayjs.locale('ru')

const qs = require('querystringify');

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());

    const filterStringify = qs.stringify({
      skip: 0,
      take: 10,
      toDown: true,
      idLike: '',
    }, true);
    dispatch(getBunnedUsers(filterStringify));
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <ToastContainer />
        <Routes>
          {/* <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
export default App;
